import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom';



import { GoogleMapScript } from './components/googleMapScript';
import LoginPage from './views/loginPage';
import PagesListPage from './views/pagesListPage';
import AddPagePage from './views/addPagePage';
import ContactListPage from './views/contactListPage';
import ContactPreviewPage from './views/contactPreviewPage';
import SiteDataPage from './views/siteDataPage';
import SeoPage from './views/seoPage';
import AddReviewPage from './views/addReviewPage';
import ReviewsListPage from './views/reviewsListPage';
import BlogListPage from './views/blogListPage';
import AddBlogPage from './views/addBlogPage';
import AddBlogCategoryPage from './views/addBlogCategoryPage';
import BlogCategoriesPage from './views/blogCategoriesPage';
import RestrictionsPage from './views/restrictionsPage';
import AddRestrictionForm from './views/addRestrictionForm';
import ReservationsPage from './views/reservationsPage';
import ReservationPage from './views/reservationPage';

//Components for adding custom pages
import WebPagesListPage from './views/webPagesListPage';
import WebAddPagePage from './views/webAddPagePage';

class Routes extends Component {

    componentDidMount() {

    }

    render() {
        return (
            <Router >
                <div>

                    <GoogleMapScript API_KEY="AIzaSyAAqbIo7N0_Rpwtay3-CWzo5gkfpgWZ4to" />

                    <Switch className="react-switch">


                        {/* WEB PAGES ROUTES */}
                        <Route
                            path="/custom-pages"
                            exact
                            render={(...renderProps) => (
                                <WebPagesListPage {...renderProps} {...this.props} />
                            )}
                        />

                        {/* <Route
                            path="/custom-pages/new"
                            exact
                            render={(...renderProps) => (
                                <WebAddPagePage {...renderProps} {...this.props} />
                            )}
                        /> */}

                        <Route
                            path="/custom-pages/:id"
                            exact
                            render={(...renderProps) => (
                                <WebAddPagePage {...renderProps} {...this.props} />
                            )}
                        />

                        {/* WEB PAGES ROUTES */}



                        <Route
                            path="/"
                            exact
                            render={(...renderProps) => (
                                <PagesListPage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/login"
                            exact
                            render={(...renderProps) => (
                                <LoginPage {...renderProps} {...this.props} />
                            )}
                        />
                        <Route
                            path="/pages"
                            exact
                            render={(...renderProps) => (
                                <PagesListPage {...renderProps} {...this.props} />
                            )}
                        />
                        

                        <Route
                            path="/pages/new"
                            exact
                            render={(...renderProps) => (
                                <AddPagePage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/pages/:id"
                            exact
                            render={(...renderProps) => (
                                <AddPagePage {...renderProps} {...this.props} />
                            )}
                        />


                        <Route
                            path="/reviews"
                            exact
                            render={(...renderProps) => (
                                <ReviewsListPage {...renderProps} {...this.props} />
                            )}
                        />
                        <Route
                            path="/reviews/new"
                            exact
                            render={(...renderProps) => (
                                <AddReviewPage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/reviews/:id"
                            exact
                            render={(...renderProps) => (
                                <AddReviewPage {...renderProps} {...this.props} />
                            )}
                        />

                        



                        <Route
                            path="/blog"
                            exact
                            render={(...renderProps) => (
                                <BlogListPage {...renderProps} {...this.props} />
                            )}
                        />
                        <Route
                            path="/blog/new"
                            exact
                            render={(...renderProps) => (
                                <AddBlogPage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/blog/:id"
                            exact
                            render={(...renderProps) => (
                                <AddBlogPage {...renderProps} {...this.props} />
                            )}
                        />


                                                <Route
                            path="/blogCategories"
                            exact
                            render={(...renderProps) => (
                                <BlogCategoriesPage {...renderProps} {...this.props} />
                            )}
                        />
                        <Route
                            path="/blogCategories/new"
                            exact
                            render={(...renderProps) => (
                                <AddBlogCategoryPage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/blogCategories/:id"
                            exact
                            render={(...renderProps) => (
                                <AddBlogCategoryPage {...renderProps} {...this.props} />
                            )}
                        />



                                                <Route
                            path="/restrictions"
                            exact
                            render={(...renderProps) => (
                                <RestrictionsPage {...renderProps} {...this.props} />
                            )}
                        />
                        <Route
                            path="/restrictions/new"
                            exact
                            render={(...renderProps) => (
                                <AddRestrictionForm {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/restrictions/:id"
                            exact
                            render={(...renderProps) => (
                                <AddRestrictionForm {...renderProps} {...this.props} />
                            )}
                        />



                        <Route
                            path="/contacts"
                            exact
                            render={(...renderProps) => (
                                <ContactListPage {...renderProps} {...this.props} />
                            )}
                        />
                        <Route
                            path="/contacts/:id"
                            exact
                            render={(...renderProps) => (
                                <ContactPreviewPage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/reservations"
                            exact
                            render={(...renderProps) => (
                                <ReservationsPage {...renderProps} {...this.props} />
                            )}
                        />
                        <Route
                            path="/reservations/:id"
                            exact
                            render={(...renderProps) => (
                                <ReservationPage {...renderProps} {...this.props} />
                            )}
                        />


                        <Route
                            path="/config"
                            exact
                            render={(...renderProps) => (
                                <SiteDataPage {...renderProps} {...this.props} />
                            )}
                        />
                        <Route
                            path="/seo"
                            exact
                            render={(...renderProps) => (
                                <SeoPage {...renderProps} {...this.props} />
                            )}
                        />




                    </Switch>
                </div>
            </Router >
        );
    }
}

export default Routes;