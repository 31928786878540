import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import Isvg from 'react-inlinesvg';

import Text from './fields/text';
import HtmlImage from './fields/htmlImage';
import Html from './fields/html';

import image from '../../assets/svg/image.svg';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

const required = value => value ? undefined : "Required"

const renderTextField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

    <Text
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        {...input}
    />
)



const renderHtmlField = ({
    input,
    placeholder,
    meta: { touched, error },
}) => (

    <Html
        placeholder={placeholder}
        errorText={touched && error}
        error={touched && error}

        {...input}
    />
)



const form = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    console.log(pristine, submitting);

    return (
        <form onSubmit={handleSubmit}>
            <Row>
                
                {/* <Col lg="12" >
                    <Container fluid className="form-box">
                        <Row>
                            <Col lg="12">
                                <h3 className="title">Page name</h3>
                                <h6 className="subtitle">Enter page name</h6>

                            </Col>
                            <Col lg="6" className="input-wrap">
                                <Field
                                    name="name"
                                    component={renderTextField}
                                    label={"Page name"}
                                    placeholder="Enter name of page"

                                ></Field>



                            </Col>
                            <Col lg="6" className="input-wrap">


                                <Field
                                    name="alias"
                                    component={renderHtmlField}
                                    placeholder="Enter text"

                                ></Field>

                            </Col>

                        </Row>
                    </Container>
                </Col> */}

                {/* test */}

                <Col lg="6" >
                    <Container fluid className="form-box">
                        <Row  className="simple-input">

                            <Col lg="12">
                                <h3 className="title">Page name</h3>
                                <h6 className="subtitle">Enter page name</h6>

                            </Col>

                            <Field
                                name="name"
                                component={renderTextField}
                                label={"Page name"}
                                placeholder="Enter name of page"
                            ></Field>


                        </Row>
                    </Container>
                </Col>


                <Col lg="6" >
                    <Container fluid className="form-box">
                        <Row>

                            <Col lg="12">
                                <h3 className="title">Page heading</h3>
                                <h6 className="subtitle">Enter page heading</h6>

                            </Col>
                            <Field
                                    name="alias"
                                    component={renderHtmlField}
                                    placeholder="Enter text"

                                ></Field>


                        </Row>
                    </Container>
                </Col>

                {/* test */}


                <Col lg="6" >
                    <Container fluid className="form-box">
                        <Row>
                            <Col lg="12">
                                <h3 className="title">Content</h3>
                                <h6 className="subtitle">Enter text</h6>

                            </Col>
                            <Field
                                name="content"
                                component={renderHtmlField}
                                placeholder="Enter text"

                            ></Field>



                        </Row>
                    </Container>
                </Col>
                <Col lg="6" >
                    <Container fluid className="form-box">
                        <Row>
                            <Col lg="12">
                                <h3 className="title">Circle background text</h3>
                                <h6 className="subtitle">Enter text</h6>

                            </Col>
                            <Field
                                name="bottomContent"
                                component={renderHtmlField}
                                placeholder="Enter text"

                            ></Field>



                        </Row>
                    </Container>
                </Col>

                <Col lg="12">
                    <button className="button">Save</button>

                </Col>

            </Row>

        </form>
    )
}

export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)
