import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import logo from '../assets/images/logo.png';
import bg from '../assets/images/login-bg.png';

import upArrow from '../assets/svg/up.svg';
import downArrow from '../assets/svg/down.svg';

import editIcon from '../assets/svg/edit.svg';
import deleteIcon from '../assets/svg/delete.svg';


import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';

import 'react-big-calendar/lib/sass/styles.scss';

import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
const localizer = momentLocalizer(moment)


class BlogCategoriesPage extends Component {
    constructor(props) {
        super(props);
        this.fetchPages = this.fetchPages.bind(this);
        this.deletePage = this.deletePage.bind(this);
        this.renderEvent = this.renderEvent.bind(this);

        this.state = {
            items: []
        };
    }

    componentDidMount() {
        this.fetchPages();
    }

    fetchPages() {
        if (!localStorage.auth) {
            return;
        }

        fetch('https://api.boostercoaching.ch/admin/restrictions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${localStorage.getItem('auth')}`
            },
        }).then((res) => res.json()).then((result) => {
            console.log(result);
            this.setState({
                items: result
            })
        })

    }

    deletePage(id) {
        if (!localStorage.auth) {
            return;
        }

        fetch('https://api.boostercoaching.ch/admin/restrictions/delete/' + id, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${localStorage.getItem('auth')}`

            },
        }).then((res) => this.fetchPages())


    }

    renderEvent({ event }) {
        console.log(event);
        return (
            <div onClick={() => {
                if (event.reservation){
                    this.props[0].history.push(`/reservations/${event.reservation.id}`)
                }
            }}>
                {
                    event.reservation ?
                        <p className="reservation-block-name">{event.reservation.name}</p>
                    :

                    null
                }
            </div>
        )
    }




    render() {

        return (
            <div className="page-wrap">
                {
                    !localStorage.auth ? <Redirect to='/login' /> : null
                }

                <Container fluid className="table">

                    <Row className="page-title">
                        <Col lg="12">
                            <h3>Busy timelines</h3>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12">
                            <Calendar
                                localizer={localizer}
                                components={{
                                    event: this.renderEvent,
                                    dateCellWrapper: (data) => {

                                        let isDisallowed = false;
                                        let id = null;

                                        for (let i = 0; i < this.state.items.length; i++) {
                                            if (this.state.items[i].tsFrom == Math.floor(data.value.getTime() / 1000) && this.state.items[i].tsTo == Math.floor(new Date(data.value.getFullYear(), data.value.getMonth(), data.value.getDate(), 23, 59, 59).getTime() / 1000)) {
                                                isDisallowed = true;
                                                id = this.state.items[i].id;
                                                break;
                                            }
                                        }

                                        return (
                                            <div className={data.children.props.className}>
                                                {isDisallowed ?

                                                    <button className="date-button" onClick={() => {
                                                        this.deletePage(id);

                                                    }}>Disallowed</button>
                                                    :
                                                    <button className="date-button allow-date-button" onClick={() => {
                                                        fetch('https://api.boostercoaching.ch/admin/restrictions/new', {
                                                            method: 'POST',
                                                            headers: {
                                                                'Content-Type': 'application/json',
                                                                'Authorization': `Basic ${localStorage.getItem('auth')}`

                                                            },
                                                            body: JSON.stringify({ tsFrom: Math.floor(data.value.getTime() / 1000), tsTo: Math.floor(new Date(data.value.getFullYear(), data.value.getMonth(), data.value.getDate(), 23, 59, 59).getTime() / 1000) })
                                                        }).then((res) => res.json()).then((result) => {
                                                            this.fetchPages();
                                                        })

                                                    }}>Allowed</button>
                                                }
                                            </div>
                                        )
                                    },

                                    agenda: {
                                        event: (data) => {
                                            console.log(data)
                                            let id = null;

                                            for (let i = 0; i < this.state.items.length; i++) {
                                                if (this.state.items[i].tsFrom == Math.floor(data.event.start.getTime() / 1000) && this.state.items[i].tsTo == Math.floor( data.event.end.getTime() / 1000)) {
                                                    id = this.state.items[i].id;
                                                    break;
                                                }
                                            }

                                            console.log(id);
    
                                            return <button className="agenda-button" onClick={() => this.deletePage(id)}>Delete</button>
                                        }
                                    }
                                }}
                                events={this.state.items.map((item) => { return { start: moment.unix(item.tsFrom).toDate(), end: moment.unix(item.tsTo).toDate(), reservation: item.reservationData } })}
                                startAccessor="start"
                                endAccessor="end"
                                style={{ height: 870 }}
                            />

                        </Col>

                    </Row>
                    <Row className="table-head">
                        {/*<Col lg="1">
                            <div className="checkbox-wrap">
                                <div className="checkbox"></div>
                            </div>
            </Col>*/}
                        <Col lg="5">
                            {/*<div className="sort-wrap">
                                <button><Isvg src={upArrow} /><Isvg src={downArrow} /></button>

        </div>*/}
                            <span className="name">FROM</span>
                        </Col>
                        <Col lg="5">
                            {/*<div className="sort-wrap">
                                <button><Isvg src={upArrow} /><Isvg src={downArrow} /></button>

        </div>*/}
                            <span className="name">TO</span>
                        </Col>

                        <Col lg="2" className="actions">

                            <span className="name">OPTIONS</span>
                        </Col>

                    </Row>
                    {
                        this.state.items.map((item, idx) => {
                            return (
                                <Row className="table-row" key={idx}>
                                    {/* <Col lg="1">
                                        <div className="checkbox-wrap">
                                            <div className="checkbox"></div>
                                        </div>
                            </Col>*/}
                                    <Col lg="5">
                                        <span className="value">{moment.unix(item.tsFrom).format('DD.MM.YYYY HH:mm')}

                                        </span>
                                    </Col>
                                    <Col lg="5">
                                        <span className="value">
                                            {moment.unix(item.tsTo).format('DD.MM.YYYY HH:mm')}</span>
                                    </Col>
                                    <Col lg="2" className="actions">
                                        <Link to={`/restrictions/${item.id}`}><Isvg src={editIcon} /></Link>
                                        {<button onClick={() => this.deletePage(item.id)}><Isvg src={deleteIcon} /></button>}

                                    </Col>

                                </Row>

                            )
                        })
                    }


                </Container>

                {/*<Container fluid className="bottom-wrap">
                    <Row>
                        <Col lg="12">
                            <Link to='/pages/new'>
                                <button>Add page</button>
                            </Link>
                        </Col>
                    </Row>

                </Container>*/}


            </div>
        );
    }
}

export default Page(BlogCategoriesPage);