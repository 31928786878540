import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import Isvg from 'react-inlinesvg';

import Text from './fields/text';
import Textarea from './fields/textarea';
import HtmlImage from './fields/htmlImage';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

const required = value => value ? undefined : "Required"

const renderTextField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

        <Text
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            {...input}
        />
    )

    const renderTextareaField = ({
        input,
        placeholder,
        label,
        meta: { touched, error },
    }) => (
    
            <Textarea
                placeholder={placeholder}
                label={label}
                errorText={touched && error}
                error={touched && error}
                {...input}
            />
        )
    
    
    

const renderHtmlImageField = ({
    input,
    placeholder,
    meta: { touched, error },
}) => (

        <HtmlImage
            placeholder={placeholder}
            errorText={touched && error}
            error={touched && error}

            {...input}
        />
    )



const form = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    console.log(pristine, submitting);

    return (
        <form onSubmit={handleSubmit}>
            <Row>
                <Col lg="6" >
                    <Container fluid className="form-box">
                        <Row>
                            <Col lg="12">
                                <h3 className="title">Site name and contact</h3>
                                <h6 className="subtitle">Enter the basic information</h6>

                            </Col>
                            <Col lg="12" className="input-wrap">
                                <Field
                                    name="name"
                                    component={renderTextField}
                                    label={"Site name"}
                                    placeholder="Please enter a website name"

                                ></Field>

                            </Col>
                            <Col lg="12" className="input-wrap">
                                <Field
                                    name="phone"
                                    component={renderTextField}
                                    label={"Contact phone"}
                                    placeholder="Please enter contact phone"

                                ></Field>

                            </Col>
                            <Col lg="12" className="input-wrap">
                                <Field
                                    name="email"
                                    component={renderTextField}
                                    label={"E-mail address"}
                                    placeholder="Enter your E-mail address"

                                ></Field>

                            </Col>

                        </Row>
                    </Container>
                </Col>

                  <Col lg="6" >
                    <Container fluid className="form-box">
                        <Row>
                            <Col lg="12">
                                <h3 className="title">Contact information</h3>
                                <h6 className="subtitle">This information will be displayed in the contract page</h6>

                            </Col>
                            <Col lg="12" className="input-wrap">
                                <Field
                                    name="address"
                                    component={renderTextareaField}
                                    label={"Address"}
                                    placeholder="Enter the street and number and other information"

                                ></Field>

                            </Col>
                            <Col lg="12" className="input-wrap">
                                <Field
                                    name="gps"
                                    component={renderTextField}
                                    label={"GPS coordinates"}
                                    placeholder="Enter the coordinates of your location"

                                ></Field>

                            </Col>
                        </Row>
                    </Container>
                </Col>

                <Col lg="12">
                    <button className="button">Save</button>

                </Col>

            </Row>

        </form>
    )
}

export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)
