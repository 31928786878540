import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import logo from '../assets/images/logo.png';
import bg from '../assets/images/login-bg.png';

import upArrow from '../assets/svg/up.svg';
import downArrow from '../assets/svg/down.svg';

import editIcon from '../assets/svg/edit.svg';
import deleteIcon from '../assets/svg/delete.svg';


import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';

class PagesListPage extends Component {
    constructor(props) {
        super(props);
        this.fetchPages = this.fetchPages.bind(this);
        this.deletePage = this.deletePage.bind(this);

        this.state = {
            items: []
        };
    }

    componentDidMount() {
        this.fetchPages();
    }

    fetchPages() {
        if (!localStorage.auth){
            return;
        }

        fetch('https://api.boostercoaching.ch/admin/pages', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${localStorage.getItem('auth')}`
            },
        }).then((res) => res.json()).then((result) => {
            console.log(result);
            this.setState({
                items: result
            })
        })

    }

    deletePage(id){
        if (!localStorage.auth){
            return;
        }

        fetch('https://api.boostercoaching.ch/admin/pages/delete/'+id, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${localStorage.getItem('auth')}`

            },
        }).then((res) => this.fetchPages())


    }


    render() {

        return (
            <div className="page-wrap">
                            {
                    !localStorage.auth ? <Redirect to='/login' /> : null
                }

                <Container fluid className="table">

                    <Row className="page-title">
                        <Col lg="12">
                            <h3>All pages</h3>
                        </Col>
                    </Row>
                    <Row className="table-head">
                         {/*<Col lg="1">
                            <div className="checkbox-wrap">
                                <div className="checkbox"></div>
                            </div>
            </Col>*/}
                        <Col lg="10">
                            {/*<div className="sort-wrap">
                                <button><Isvg src={upArrow} /><Isvg src={downArrow} /></button>

        </div>*/}
                            <span className="name">NAME</span>
                        </Col>
                        <Col lg="2" className="actions">

                            <span className="name">OPTIONS</span>
                        </Col>

                    </Row>
                    {
                        this.state.items.map((item, idx) => {
                            return (
                                <Row className="table-row" key={idx}>
                                    {/* <Col lg="1">
                                        <div className="checkbox-wrap">
                                            <div className="checkbox"></div>
                                        </div>
                            </Col>*/}
                                    <Col lg="10">
                                        <span className="value">{item.name}</span>
                                    </Col>
                                    <Col lg="2" className="actions">
                                        <Link to={`/pages/${item.id}`}><Isvg src={editIcon} /></Link>
                                      {/*  <button onClick={() => this.deletePage(item.id)}><Isvg src={deleteIcon} /></button>*/}

                                    </Col>

                                </Row>

                            )
                        })
                    }


                </Container>

                {/*<Container fluid className="bottom-wrap">
                    <Row>
                        <Col lg="12">
                            <Link to='/pages/new'>
                                <button>Add page</button>
                            </Link>
                        </Col>
                    </Row>

                </Container>*/}


            </div>
        );
    }
}

export default Page(PagesListPage);