import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import logo from '../assets/svg/logo.svg';
import menu from '../assets/svg/menu.svg';

import list from '../assets/svg/list.svg';
import add from '../assets/svg/add.svg';
import rocket from '../assets/svg/rocket.svg';
import mail from '../assets/svg/mail.svg';
import settings from '../assets/svg/settings.svg';
import exit from '../assets/svg/exit.svg';


class Sidebar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            _show: true
        };
    }




    render() {

        return (

            <div className={this.state._show ? `sidebar` : 'sidebar sidebar-hidden'}>
                <div className="top">
                    <div className="logo">
                        <Isvg src={logo} />
                    </div>

                </div>

                <div className="items">
                    <h6>CONTENT</h6>
                    <ul>

                        <li>
                            <Link to='/pages' className={this.props[0].location.pathname == '/pages' ? 'active' : null}>
                                <Isvg src={list} />
                                All pages
                            </Link>
                        </li>

                        
                        {/* <li>
                            <Link to='/pages/new' className={this.props[0].location.pathname == '/pages/new' ? 'active' : null}>
                                <Isvg src={add} />
                                Add page
                            </Link>
                        </li> */}
                       

                    </ul>

                    <h6>CUSTOM PAGES</h6>
                    <ul>

                        <li>
                            <Link to='/custom-pages' className={this.props[0].location.pathname == '/pages' ? 'active' : null}>
                                <Isvg src={list} />
                                All custom pages
                            </Link>
                        </li>

                        
                         {/* <li>
                            <Link to='/custom-pages/new' className={this.props[0].location.pathname == '/pages/new' ? 'active' : null}>
                                <Isvg src={add} />
                                Add custom page
                            </Link>
                        </li> 
                        */}

                    </ul>

                    <h6>TEMOIGNAGES</h6>
                    <ul>
                        <li>
                            <Link to='/reviews' className={this.props[0].location.pathname == '/reviews' ? 'active' : null}>
                                <Isvg src={list} />
                                All reviews
                            </Link>
                        </li>
                        <li>
                            <Link to='/reviews/new' className={this.props[0].location.pathname == '/reviews/new' ? 'active' : null}>
                                <Isvg src={add} />
                                Add review
                            </Link>
                        </li>

                    </ul>
                    <h6>BLOG</h6>
                    <ul>
                        <li>
                            <Link to='/blog' className={this.props[0].location.pathname == '/blog' ? 'active' : null}>
                                <Isvg src={list} />
                                All news
                            </Link>
                        </li>
                        <li>
                            <Link to='/blog/new' className={this.props[0].location.pathname == '/blog/new' ? 'active' : null}>
                                <Isvg src={add} />
                                Add blog
                            </Link>
                        </li>

                    </ul>
                    <h6>BLOG CATEGORIES</h6>
                    <ul>
                        <li>
                            <Link to='/blogCategories' className={this.props[0].location.pathname == '/blogCategories' ? 'active' : null}>
                                <Isvg src={list} />
                                All categories
                            </Link>
                        </li>
                        <li>
                            <Link to='/blogCategories/new' className={this.props[0].location.pathname == '/blogCategories/new' ? 'active' : null}>
                                <Isvg src={add} />
                                Add category
                            </Link>
                        </li>

                    </ul>
                    <h6>BUSY TIMELINES</h6>
                    <ul>
                        <li>
                            <Link to='/restrictions' className={this.props[0].location.pathname == '/restrictions' ? 'active' : null}>
                                <Isvg src={list} />
                                All busy timelines
                            </Link>
                        </li>
                        <li>
                            <Link to='/restrictions/new' className={this.props[0].location.pathname == '/restrictions/new' ? 'active' : null}>
                                <Isvg src={add} />
                                Add busy timeline
                            </Link>
                        </li>

                    </ul>
                    <h6>RESERVATIONS</h6>
                    <ul>
                        <li>
                            <Link to='/reservations' className={this.props[0].location.pathname == '/reservations' ? 'active' : null}>
                                <Isvg src={list} />
                                All reservations
                            </Link>
                        </li>

                    </ul>

                    <h6>CONTACT</h6>
                    <ul>
                        <li>
                            <Link to='/contacts' className={this.props[0].location.pathname == '/contacts' ? 'active' : null}>
                                <Isvg src={mail} />
                                All contacts
                            </Link>
                        </li>

                    </ul>


                    <h6>SETTINGS</h6>
                    <ul>
                        <li>
                            <Link to='/config' className={this.props[0].location.pathname == '/config' ? 'active' : null}>
                                <Isvg src={settings} />
                                Site data
                            </Link>
                        </li>
                        <li>
                            <Link to='/seo' className={this.props[0].location.pathname == '/seo' ? 'active' : null}>
                                <Isvg src={rocket} />
                                SEO tags
                            </Link>
                        </li>
                    </ul>

                    <ul className="logout">
                        <li onClick={() => localStorage.removeItem('auth')}>
                            <Link to='/login' >
                                <Isvg src={exit} />
                                Logout
                            </Link>
                        </li>
                    </ul>

                </div>

                    <div className="menu" onClick={() => this.setState({ _show: !this.state._show })}>
                        <Isvg src={menu} />
                    </div>

            </div>
        )
    }

};

export default Sidebar;