import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import logo from '../assets/images/logo.png';
import bg from '../assets/images/login-bg.png';

import upArrow from '../assets/svg/up.svg';
import downArrow from '../assets/svg/down.svg';

import editIcon from '../assets/svg/edit.svg';
import image from '../assets/svg/image.svg';

import PageForm from '../components/forms/pageForm';
import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';

class AddPagePage extends Component {
    constructor(props) {
        super(props);
        this.addPage = this.addPage.bind(this);
        this.editPage = this.editPage.bind(this);


        this.state = {

        };
    }

    addPage(data) {
        console.log(data);

        fetch('https://api.boostercoaching.ch/admin/pages/new', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${localStorage.getItem('auth')}`

            },
            body: JSON.stringify(data)
        }).then((res) => res.json()).then((result) => {
            this.props[0].history.push('/pages')
        })
    }
    editPage(data) {
        console.log(data);

        fetch('https://api.boostercoaching.ch/admin/pages/edit/' + this.props[0].match.params.id, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${localStorage.getItem('auth')}`

            },
            body: JSON.stringify(data)
        }).then((res) => res.json()).then((result) => {
            this.props[0].history.push('/pages')

        })
    }


    componentDidMount() {
        if (this.props[0].match.params.id) {
            fetch('https://api.boostercoaching.ch/admin/pages/get/' + this.props[0].match.params.id, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Basic ${localStorage.getItem('auth')}`

                }
            }).then((res) => res.json()).then((result) => {
                this.setState({
                    initialValues: result
                })
                console.log(result);
            })

        }
    }


    render() {
        return (
            <div className="page-wrap">
                {
                    !localStorage.auth ? <Redirect to='/login' /> : null
                }

                <Container fluid>

                    <Row className="page-title">
                        <Col lg="12">
                            { this.props[0].match.params.id ? <h3>Edit page</h3> : <h3>Add page</h3>}
                        </Col>

                    </Row>
                    {this.state.initialValues ?
                        <PageForm initialValues={this.state.initialValues} onSubmit={this.editPage} />

                        :
                        <PageForm onSubmit={this.addPage} />
                    }
                </Container>


            </div>
        );
    }
}

export default Page(AddPagePage);