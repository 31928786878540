import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import logo from '../assets/images/logo.png';
import bg from '../assets/images/login-bg.png';

import upArrow from '../assets/svg/up.svg';
import downArrow from '../assets/svg/down.svg';

import editIcon from '../assets/svg/edit.svg';
import image from '../assets/svg/image.svg';

import ReviewForm from '../components/forms/reviewForm';
import ReviewFormNew from '../components/forms/reviewFormNew';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';

class AddReviewPage extends Component {
    constructor(props) {
        super(props);
        this.addPage = this.addPage.bind(this);
        this.editPage = this.editPage.bind(this);


        this.state = {

        };
    }

    addPage(data) {
        if (!localStorage.auth){
            return;
        }

        console.log(data);

        fetch('https://api.boostercoaching.ch/admin/reviews/new', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${localStorage.getItem('auth')}`

            },
            body: JSON.stringify(data)
        }).then((res) => res.json()).then((result) => {
            this.props[0].history.push('/reviews')
        })
    }
    editPage(data) {
        if (!localStorage.auth){
            return;
        }

        console.log(data);

        fetch('https://api.boostercoaching.ch/admin/reviews/edit/'+this.props[0].match.params.id, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${localStorage.getItem('auth')}`

            },
            body: JSON.stringify(data)
        }).then((res) => res.json()).then((result) => {
            this.props[0].history.push('/reviews')

        })
    }


    componentDidMount() {
        if (!localStorage.auth){
            return;
        }

        if (this.props[0].match.params.id) {
            fetch('https://api.boostercoaching.ch/admin/reviews/get/' + this.props[0].match.params.id, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Basic ${localStorage.getItem('auth')}`

                }
            }).then((res) => res.json()).then((result) => {
                this.setState({
                    initialValues: result
                })
                console.log(result);
            })

        }
    }

    componentDidUpdate(prevProps){
        if (prevProps[0].location.pathname !== this.props[0].location.pathname){
            console.log(this.props[0].location.pathname)
            if (this.props[0].location.pathname == '/reviews/new'){
                this.setState({
                    initialValues: null
                })
            }
        }
    }


    render() {
        console.log(this.props[0].match.params);
        return (
            <div className="page-wrap">
                            {
                    !localStorage.auth ? <Redirect to='/login' /> : null
                }

                <Container fluid>

                    <Row className="page-title">
                        <Col lg="12">
                        { this.props[0].match.params.id ? <h3>Edit review</h3> : <h3>Add review</h3>}
                        </Col>

                    </Row>
                    {this.state.initialValues ?
                        <ReviewForm initialValues={this.state.initialValues} onSubmit={this.editPage} />

                        :
                        <ReviewFormNew onSubmit={this.addPage} />
                    }
                </Container>


            </div>
        );
    }
}

export default Page(AddReviewPage);