import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import Isvg from 'react-inlinesvg';

import Text from './fields/text';
import HtmlImage from './fields/htmlImage';
import DateTime from './fields/dateTime';

import image from '../../assets/svg/image.svg';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

const required = value => value ? undefined : "Required"

const renderTextField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

        <Text
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            {...input}
        />
    )



const renderDateTimeField = ({
    input,
    placeholder,
    meta: { touched, error },
}) => (

        <DateTime
            placeholder={placeholder}
            errorText={touched && error}
            error={touched && error}

            {...input}
        />
    )



const form = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    console.log(pristine, submitting);

    return (
        <form onSubmit={handleSubmit}>
            <Row>
                <Col lg="12" >
                    <Container fluid className="form-box">
                        <Row>
                            <Col lg="12">
                                <h3 className="title">From - To</h3>
                                <h6 className="subtitle">Enter date - range</h6>

                            </Col>
                            <Col lg="3" className="input-wrap">
                                <Field
                                    name="tsFrom"
                                    component={renderDateTimeField}
                                    label={"From"}
                                    placeholder="Enter name of category"

                                ></Field>

                            </Col>
                            <Col lg="3" className="input-wrap">
                                <Field
                                    name="tsTo"
                                    component={renderDateTimeField}
                                    label={"To"}
                                    placeholder="Enter name of category"

                                ></Field>

                            </Col>

                        </Row>
                    </Container>
                </Col>

                <Col lg="12">
                    <button className="button">Save</button>

                </Col>

            </Row>

        </form>
    )
}

export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)
